// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1856284506827395072',
    publicVapidKey : 'BLbbXgSj_1x42pXFdwD3h_Ea-82vjtTUdbroFfV3TJYrRfRSPOVD-tZ_E2Jj64iFXDmAAA3g4ommwkpBcB88wgw',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'1201674067761049',
    model:'模板四',
    appurl:'https://win92vip.com/',
  }),
});
